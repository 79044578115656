.videoBox {
    padding: 140px 0 140px;
    width: 100%;
    background: #EFFDF9;
}
.themesLine {
    .toolItem {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background: #FFFFFF;
        border-radius: 20px;
        box-shadow:none;
        //transition: 0.3s;
        //opacity: 0.45;
        border: 1px solid rgba(164, 165, 177, 0.45);
        &:hover{
            box-shadow: none;
        }
    }
    .itemName {
        font-size: 16px;
        font-weight: 500;
        color: #1A1B1C;
        line-height: 20px;
        display: block;
    }
}
.videoTitle {
    width: 90%;
    //padding: 0 10px;
    margin: 0 auto 30px;
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    color: #1A1B1C;
    line-height: 64px;
}
.toolItem {
    position: relative;
    width: 210px;
    height: 210px;
    display: flex;
    margin: 0 10px 23px 10px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 4px 30px 0 rgba(179, 199, 207, 0.24);
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    transition: all 0.3s;
    text-decoration: none;
    .icon_box {
        width: 70px;
        height: 70px;
    }
    &:hover{
         box-shadow: 0 4px 30px 0 rgba(179, 199, 207, 0.7);
        border: 1px solid #FF822C;
        .itemIcon {
            transition: all 0.5s;
            transform: translate(6px);
            //box-shadow: 0 4px 30px 0 rgba(179, 199, 207, 0.7);
        }
     }
}
.itemEnd {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    color: #696E7A;
    line-height: 28px;
    .itemEndTitle {
        padding: 0 20px;
        margin: -70px 0 0 0;
        text-align: left;
        color: #1a1b1c;
    }
}
.itemName {
    font-size: 16px;
    font-weight: 500;
    color: #1A1B1C;
    line-height: 20px;
    text-align: center;
    padding: 0 10px;
}
.contentList {
    max-width: 1380px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.itemAnimationStart{
    transform: translateY(20px);
    opacity: 0;
}

.itemAnimationEnd {
    animation: featureAnimation 0.5s linear calc(var(--d) * 1s) forwards;
}

@keyframes  featureAnimation{
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.itemContent{
    //width: 60%;
    margin-top: -40px;
    margin-left: 25px;
    //height: 56px;
    font-size: 17px;
    text-align: left;
    font-weight: 500;
    color: #696E7A;
    line-height: 28px;
}
.itemIcon{
    width: 16px;
    height: 16px;
    position: absolute;
    right: 40px;
    bottom: 30px;
    //bottom: -55px;
    //right: 30px;
    //position: relative;
    //float: right;
}
@media screen and (max-width: 500px) {
    .videoBox {
        padding: 70px 0 70px;
    }
    .toolItem {
        width: 170px;
        height: 170px;
        margin: 0 5px 10px 5px;
    }
}
